.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;
  margin: 0;
}
.leaflet-control-zoom-in {
  margin-bottom: 8px;
}
.leaflet-control-zoom-out{
  margin-bottom: 33px;
}

.leaflet-touch .leaflet-bar a {
  color: #7f869d;
  /* line-height: 26px; */
  border-radius: 14px!important;
  padding: 13px;
  padding-top: 10px;
  width: 43px;
  height: 43px;
  line-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 36px;
  /* border-radius: 30px!important; */
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-touch .leaflet-control-attribution {
  margin-right: 15px;
}

#expandMapIcon:hover {
  background-color: #f4f4f4;
}

.leaflet-draw-draw-polygon.leaflet-draw-toolbar-button-enabled {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAQBJREFUOBGdkr0OAUEURs1WQiE8glYi0XgBlRdQeQxKnV6iFKVCRaLWqOl0QqHRiBWJREhY544Mi5jYuclx5/58n93JxmI/IgiCCkyg+GPF3kaYgyNI3KAPWbsqNGU5BQv4jDONFmRC699HFhSMwBZ7hnWIfzvQYdCwqT9ma+oqqKcZRRmuEDWm2gWVXNoM0k/X/w87D4ME+0NHA/kr3+OnA3mpHEObJB3FRraTJ6nBxXQcsu8ppVYI2w5iI9GvI0UTtqYbMT9MeJoDwkZEsVnXd2KKLoe5KSJk//XJouKbKZHGFoOA2QaWIHcpufdmQkOMBqQCyEJ4WZ959RP9t7gDsgIKLC7B9PkAAAAASUVORK5CYII=') !important;
  background-color: #548cc1 !important;
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAYxJREFUOBGNU71Lw0AUf+9SamiL4ubkUHAQQfA/EKSDg7hlVOskuBU/qlBIVTSuCoJitUOnDn6giyg4+7F1TRwd1AYFxSI0z5cI5VqTNgfh7n5fvLt7QQgYulHQgGAeRGRRX56+D5B5MPqRa0ZxyCHnjojiiEisKfO3qq/MPvnp/4UYRrmnRp8PHDAgGxDwBwTuQTS+rmc0u5mTdmzEvHF8zvOEBDctOeydq9ukPtjV0+maSyqyQqj9OSKYkzGftUpAKfyCqdHU5Nvt9VmlcZy8URgnBy75AoSPMRDigEevEn3rKAmAVxwQC1QHEHy0LqHvX8SA6JTvoTdA1wm2BdgvB1zBcCdlG55DCOJtBGGoqlAiyoLXA2Hk/hpb5JZmLH6PHX8+FMrH4aFCYoOreQ1laRGx7y8km9U+UECuhQ+15carNhprMJk45DevhHLKIkS70bEunt8ujjn1+o2skdfeH03wzJgJCBabTSUKpaYQ18Dde8LTiCcEMoHQAgVNEIrZrahWJqN9uzp5/AJQi3tAXYC1MAAAAABJRU5ErkJggg==');
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-draw-draw-rectangle.leaflet-draw-toolbar-button-enabled {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAACVJREFUOBFj/A8EDBQCJgr1g7WPGoIZiqNhMhommCGAKTLM0gkAiz4EHsycIQUAAAAASUVORK5CYII=') !important;
  background-color: #548cc1 !important;
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAACdJREFUOBFjrG+b+5+BQsBEoX6w9lFDMENxNExGwwQzBDBFhlk6AQBXhQLDAQHoLwAAAABJRU5ErkJggg==');
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-draw-draw-circle.leaflet-draw-toolbar-button-enabled {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAALZJREFUOBFjYEAD////5wHiHCDeDcTPgPgnlAbxQeI8aFpQuUAFYUD8AojxAZB8GKpOKA8oUYlPJxa5ShSDQCZjUUSMENhFjECVID/eAWJxFJOJ47wEKlNhAhIJQEyOAUBtYH0JIEP8QTwKgD/IO8+ABkhSYMhzkCE/gQawUWDIL5B33lJgAEjrW5AhVyk05CrIkI0UGrKRKukE7Ahg4FKUYuE+ARpEWd6BmQR1Efm5GMkgkssTAFstiaeG8El0AAAAAElFTkSuQmCC') !important;
  background-color: #548cc1 !important;
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAQBJREFUOBGlVKsOwkAQnF3NQyD4ARwfU1csCZIEDaiiQJMgIVhw/RgcP4BA8NAcuwuXlKYBel3T69zOdG9vp4RcJKt9Ddd7X+AIznUBagHuDKKDYCkatW0yjO9ZGmVfksU6xgNLB7SzeHYthBMYo2Q82Huc/WI230zcA7tvApqr+5qn+Z5rlWgFuuHBf5/E6GlFZD243I6/KigStqM16x3WJoYIqKjxhK89iYq+UgKL+HWNJSj5VBkDqUTnoEpQS0RkkCqFO/N7EsNlZJK1sWm4gjFTVi/YfQcoGU/4bGYSLwRowDwkZtTjQEeXiaZlhDTfm9C848mhLv4QsaoC/idPTitj7kYI1qoAAAAASUVORK5CYII=');
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-draw-edit-edit.leaflet-draw-toolbar-button-enabled {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAAAXNSR0IArs4c6QAAAOBJREFUOBGlkz0KAjEQRhMRBCtBe7ut15/KzgPY2KkXsFCvYK8o6h08gWAplrZa21quJ1DWN0ogCCYb/OCRQObNZCGrVGDSNC1CDIVAVSmkGtxAcoUoqAnCUUwrp0wNEOTKPajAGUwSbwMqRU7gCQOQJheQTJ0NKJBvFtnkwaYP0mQSKttNYp9cp9qebGRZZ1nku21Y+/k/8sInN5j0a/LSJ5cd8sonl5DlfY/gO2unLIcYXTiANBmDycYrU5CHJrRhBx2QRFpr90P51L1vsDUjWfeQM2eZVoQqDKEFwf/4C/31j5pDux9bAAAAAElFTkSuQmCC') !important;
  background-color: #548cc1 !important;
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAAAXNSR0IArs4c6QAAAZ9JREFUOBGVk7tOAlEQhmeWVdBGXUM0NhYmJjbGCy8gFiZEX8DCROxXExOCVCcUkBAjESsbEGNpB5VKZW1itLAwvoAX1EZkZXc8s4SLuChMc+bMme+fy2YRujRxmOuH9+dJzTNyp+uBMnbDR+PZWQsqeSIYQ8AHAFdA6UbAIjPJMDMENAFoHnfUgUhkZtCkKerxnINRKhDANItIuPhvBwxDxSoQ0Ql+GUvQ27eIiLe2ACqpPzuwZ6bKhayoVQE0AXENVPcZGJ+rIhJsL9AKswCbrG6CC30itH7Nd8cRRCI9ZzVV5sSG0V4N5pjaeKh6DIMJ3PZQ6xsquCvCwVBz/McO6jCRAwx7Iryx3QyzXx8hGj+atys7wYhJJ5gF7A5iseywgea9/FS/KyPui53gFic7mUskM4PmwHAZSh9vMiHQnCQ3nvoL5lwFDPDDy2MeNG9aURS9JiBbO5DwZu3e7lTBIp/c+AIWn3KkeVeU12eQo0xKuC7WDua4Kv+KcXYk5JcipxReXxaIFsc6MVXtdUcqX8YlIN1o/aNXehcwF/gGfJilZlpz4lYAAAAASUVORK5CYII=');
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-draw-edit-remove.leaflet-draw-toolbar-button-enabled {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAARCAYAAAAG/yacAAAAAXNSR0IArs4c6QAAAGVJREFUKBVj+P//Px8SDgeyfwMxMgDxQeJwdYwgWQYSAQtQ/TUS9TCAbAJpNCFB4xmQJiGghrckaBJmIkExXOmoJmhQDNeA+Az03w94dONngNR9ZmJkZPwNZOQB8Qf86sHyeSD1AACEQl75V47zAAAAAElFTkSuQmCC') !important;
  background-color: #548cc1 !important;
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAARCAYAAAAG/yacAAAAAXNSR0IArs4c6QAAAJ5JREFUKBVjbJi0hI8BChi//vL8////kv8MDCxwMQaGP4yMjDH/udm2w8RY/n/5+RHGASrGACADgAatYPjyEy7HwsjAeA3OI5LBwmCpqM9w6oEJkeoZGMwUzrAwnH/N9//vv+PEamI8/1qYiVjFyOpGNUFDY1gGhCS30mdgov2BHOO42CB1IPVM6ekmvxmZGPOAAh9wKQaJg+RB6kDqAX5yLj+V//rlAAAAAElFTkSuQmCC');
  background-size: unset;
  background-clip: unset;
  background-position: center !important;
  margin-bottom: 8px;


}

/* .leaflet-draw-actions {
  left: 43px !important;
  top: 4px !important;
} */

.leaflet-draw-tooltip-single {
  visibility: hidden !important;
}

.leaflet-touch .leaflet-draw-actions {
  left: 42px !important;
  padding-top: 7px;
}

.leaflet-draw-actions-bottom {
  padding-top: 7px !important;
}

.leaflet-draw-actions-top {
  padding-top: 6px !important;
}

.leaflet-left .leaflet-control {
  margin-left: 30px;
}

.leaflet-top, .leaflet-bottom {
  z-index: 800 !important;
}

.replay-control {
  margin-bottom: 8px;
}

a.lines-enabled {
  background-color: #548cc1;
}


.leaflet-container {
  font-family: Raleway,sans-serif;
}
